import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function MtCookAoraki(props) {
  return (
    <>
      <SEO title="Mt Cook Tour - Christchurch to Queensland - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/mount-cook-mountain.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/mount-cook-tourist.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="AWCMQ" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Mt Cook - Christchurch to Queenstown</h1>
                <h3>Take a day tour to New Zealand's highest mountain</h3>
                <div class="highlights">
                  <ul>
                    <li>See NZ's highest mountain - Aoraki/Mt Cook</li>
                    <li>Spectacular views from the Lindis Pass</li>
                    <li>Expert driver commentary</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      Take a journey to see New Zealand's highest mountain -{" "}
                      <strong>Aoraki/Mt Cook</strong>, in the heart of the
                      pristine World Heritage Park. On your way, pass by lush
                      countryside as your driver gives you his/her vast
                      knowledge of the region. <br />
                      <br />
                      Setting off from Christchurch, stop at the Church of the
                      Good Shepherd on the shores of{" "}
                      <strong>Lake Tekapo</strong>. This is the
                      most-photographed church in New Zealand thanks to the
                      stunning turquoise backdrop of the lake - you have to see
                      it to believe it!
                      <br />
                      <br />
                      On arrival at Mt Cook Village, you'll have time to grab a
                      bite to eat (own expense) and explore the gorgeous alpine
                      community nestled at the foot of the mighty snow-capped
                      mountain.
                      <br />
                      <br />
                      Take advantage of your time here to enjoy the majestic
                      scenery. There is time to experience an optional scenic
                      helicopter or ski plane flight (check with your driver on
                      the day). This an additional cost and weather permitting.
                      <br />
                      <br />
                      Following our stop at Aoraki / Mount Cook we continue
                      south through the tawny tussock of the{" "}
                      <strong>Mackenzie Country</strong>, stopping for afternoon
                      tea (own expense) in the quaint village of{" "}
                      <strong>Omarama</strong> and a visit to the Jones Family
                      Orchard (time permitting) near <strong>Cromwell</strong>{" "}
                      in Central Otago.
                    </p>
                    <p>
                      Our tour concludes in the evening in world famous{" "}
                      <strong>Queenstown</strong>, adventure capital of New
                      Zealand.
                    </p>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                {/* ... */}
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-return">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/mtcook_zqn_return.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Mt Cook - Queenstown Return</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$99.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-return">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-christchurch">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/mtcook_zqn.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Mt Cook - Queenstown to Christchurch</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$99.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-christchurch">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MtCookAoraki
